import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GraphReport, HistoryDatagrid } from "./pages";
import { useSnackbar } from "notistack";
import { Header } from "./components";
import {
  getDeviceList,
  getDeviceListReturnItemType,
  getDeviceListReturnType,
  getHistoricalData,
  getHistoricalDataReturnItemType,
  getHistoricalDataReturnType,
} from "./api";
import { IBaseComponent } from "./pages/types";
import { AxiosResponse } from "axios";
import moment from "moment";
import MapView from "./pages/mapview";
import { SignIn } from "./pages/signin";

export const App: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [Devices, setDevices] = useState<getDeviceListReturnItemType[]>([]);
  const [SelectedDevice, setSelectedDevice] =
    useState<getDeviceListReturnItemType>();
  const [StartDate, setStartDate] = useState<Date>(new Date());
  const [EndDate, setEndDate] = useState<Date>(new Date());
  const [Data, setData] = useState<getHistoricalDataReturnItemType[]>([]);
  const [TotalData, setTotalData] = useState(0);
  const [SelectedDataParameter, setSelectedDataParameter] = useState<string>();
  const loadDevices = useCallback(async () => {
    enqueueSnackbar(`Fetching Device List.`, {
      variant: "info",
    });
    const res: AxiosResponse<getDeviceListReturnType> = await getDeviceList();
    console.log({ res });
    if (typeof res?.data === "undefined") {
      enqueueSnackbar(`Failed to fetch device list.`, {
        variant: "error",
      });
      setDevices([]);
    } else {
      enqueueSnackbar(
        `Device list fetched. ${res.data.data.length} devices fetched.`,
        {
          variant: "success",
        }
      );
      const response_data: getDeviceListReturnType = res.data;
      const filteredDevices = response_data.data.filter(
        (d) => d.dev_id === "d8ef6de040397905ae652521c9c212a2"
      );
      setDevices(filteredDevices);
      setSelectedDevice(filteredDevices[0]);
    }
  }, []);
  useEffect(() => {
    loadDevices();
  }, []);
  let rowIndexValue = useRef<number>(0);
  useEffect(() => {
    const getHistoryData = async () => {
      enqueueSnackbar(`Fetching Historic ANMS data.`, {
        variant: "info",
      });
      const res: AxiosResponse<getHistoricalDataReturnType> =
        await getHistoricalData({
          dev_id: SelectedDevice?.dev_id || "",
          start_time: moment(StartDate).format("YYYY-MM-DD") + " 00:00:00",
          end_time: moment(EndDate).format("YYYY-MM-DD") + " 23:59:59",
        });
      // console.log({ res });
      if (typeof res?.data === "undefined") {
        enqueueSnackbar(`Failed to fetch latest Historic ANMS data.`, {
          variant: "error",
        });
        setData([]);
        rowIndexValue.current = 0;
        setTotalData(0);
      } else {
        enqueueSnackbar(
          `Latest Historic ANMS data fetched. ${res.data.total} rows fetched.`,
          {
            variant: "success",
          }
        );
        const response_data: getHistoricalDataReturnType = res.data;

        setData(
          response_data.data.map((i, index) => ({
            ...i,
            timestamp: moment(i.timestamp).format("lll"),
            id: index + 1,
            location: SelectedDevice ? SelectedDevice.location : "N/A",
          }))
        );
        setSelectedDataParameter("laeqt");
        setTotalData(response_data.total);
      }
    };
    rowIndexValue.current = 0;
    console.log("Resetting rowIndexValue");
    getHistoryData();
  }, [SelectedDevice, StartDate, EndDate, enqueueSnackbar]);
  const PropsToPass: IBaseComponent = {
    Data,
    setData,
    Devices,
    setDevices,
    StartDate,
    setStartDate,
    EndDate,
    setEndDate,
    SelectedDevice,
    setSelectedDevice,
    TotalData,
    setTotalData,
  };

  return (
    <Router>
      <div className="flex-1 flex flex-col w-full items-stretch min-h-screen">
        <Header />
        <div className="p-2 flex flex-1 flex-col box-border">
          <Switch>
            <Route exact path="/signin">
              <SignIn {...PropsToPass} />
            </Route>
            <Route exact path="/graph">
              <GraphReport
                {...PropsToPass}
                setSelectedDataParam={setSelectedDataParameter}
                SelectedDataParam={SelectedDataParameter}
              />
            </Route>
            <Route exact path="/map">
              <MapView {...PropsToPass} />
            </Route>
            <Route exact path="/history">
              <HistoryDatagrid {...PropsToPass} rowIndexValue={rowIndexValue} />
            </Route>
            <Route exact path="/">
              <MapView {...PropsToPass} />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
};
