import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { IBaseComponent } from "./types";
import { getDeviceListReturnItemType } from "../api";
import debounce from "lodash.debounce";
import { FaTimesCircle } from "react-icons/fa";
import {
  getNoiseLevelColorCode,
  NoiseLevelZoneType,
} from "../util/noise_color_getter";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../store";
import { MapMarker } from "../components/mapmarker";
import moment from "moment";
import { getANMSData } from "../api/getANMSData";
import { getAliveANMSData } from "../api/getAliveANMSData";
export const MapView: React.FC<IBaseComponent> = ({
  Devices,
  setSelectedDevice,
  SelectedDevice,
}) => {
  const TagItem = useRef<HTMLDivElement | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [MapInstance, setMapInstance] =
    React.useState<google.maps.Map<Element> | null>(null);
  const mapref = useRef<Element | null>(null);
  const [SearchQuery, setSearchQuery] = useState<string>("");
  const [data, setData] = useState<any>();
  const [AliveData, setAliveData] = useState<any>();

  const [FilteredTags, setFilteredTags] =
    useState<getDeviceListReturnItemType[]>(Devices);
  const handleSearch = useCallback(
    debounce(() => {
      if (SearchQuery === "") return Devices;
      setFilteredTags(
        Devices.filter((i) =>
          JSON.stringify(i).toLowerCase().includes(SearchQuery)
        )
      );
    }, 1000),
    [SearchQuery, Devices]
  );

  const getMinNewData = async () => {
    if (!!SelectedDevice?.dev_id) {
      const result = await getANMSData(SelectedDevice?.dev_id);
      setData(result.data.data);
    }
  };
  const getSecNewData = async () => {
    if (!!SelectedDevice?.dev_id) {
      const result = await getAliveANMSData(SelectedDevice?.dev_id);
      setAliveData(result.data.data);
    }
  };

  useEffect(() => {
    setFilteredTags(Devices);
    setSearchQuery("");
  }, [Devices]);

  useEffect(() => {
    if (TagItem.current !== null)
      TagItem.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
    if (!!SelectedDevice && !!MapInstance)
      MapInstance.setCenter({
        lat: parseFloat(SelectedDevice.lat),
        lng: parseFloat(SelectedDevice.long),
      });

    const secInterval = setInterval(
      (function fetchData() {
        getSecNewData();
        return fetchData;
      })(),
      10 * 1000
    );
    const minInterval = setInterval(
      (function fetchData() {
        getMinNewData();
        return fetchData;
      })(),
      50 * 1000
    );
    return () => {
      clearInterval(secInterval);
      clearInterval(minInterval);
    };
  }, [SelectedDevice, MapInstance]);
  const { state } = useSelector((state: RootState) => state.user);
  if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <div className="flex flex-1 flex-row max-h-full overflow-hidden">
      {/* <div className="flex flex-row justify-start items-center absolute top-20 left-4 z-10 w-3/12 ring-1 rounded-md">
        <div className="p-2 rounded-l-md bg-gray-400 text-gray-800 font-semibold px-4 flex flex-row justify-end items-center">
          Search
        </div>
        {SearchQuery !== "" && (
          <span className="absolute left-24">
            <FaTimesCircle
              size="30px"
              className="text-gray-600"
              onClick={(e) => {
                setSearchQuery("");
                handleSearch();
                setFilteredTags(Devices);
              }}
            />
          </span>
        )}
        <input
          type="text"
          name="searchDevice"
          id="searchDevice"
          className="flex-1 p-2 rounded-r-md outline-none focus:bg-yellow-100 pl-12"
          value={SearchQuery}
          onChange={(e) => {
            console.log(e.target.value);
            setSearchQuery(e.target.value);
            handleSearch();
          }}
        />
      </div>
      <div
        className="gap-2 absolute top-32 left-4 z-10 flex flex-col justify-start items-stretch overflow-scroll pr-2 pb-20 w-3/12"
        style={{
          height: "90vh",
        }}
      >
        {FilteredTags.length > 0
          ? FilteredTags.map((d) => (
              <div
                className={`flex flex-col justify-start items-stretch p-4 rounded-lg z-20 ${
                  SelectedDevice?.dev_id === d.dev_id
                    ? "bg-gray-600"
                    : "bg-gray-800"
                }`}
                onClick={() => {
                  setSelectedDevice(d);
                }}
                ref={(r) => {
                  if (SelectedDevice?.dev_id === d.dev_id) TagItem.current = r;
                }}
              >
                <div className="flex flex-row justify-between items-center text-gray-200 p-4 py-1 text-md">
                  <div
                    className="rounded-full w-8 h-8"
                    style={{
                      backgroundColor: getNoiseLevelColorCode(
                        parseFloat(d.laeqt),
                        d.zone as NoiseLevelZoneType
                      ),
                    }}
                  ></div>
                  <div className="rounded-full px-2 font-bold text-gray-700 bg-gray-100">
                    {d.zone}
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-md">
                  <div>Device Location: </div>
                  <div className="text-gray-50 text-md font-bold px-4">
                    {d.location}
                  </div>
                  <div className="flex-1 flex flex-row justify-end items-center"></div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-md">
                  <div>Device Id: </div>
                  <div
                    className="text-gray-50 text-md font-bold px-4"
                    style={{ textOverflow: "ellipsis" }}
                  >
                    {d.dev_id}
                  </div>
                </div>
              </div>
            ))
          : null}
      </div> */}
      <GoogleMapReact
        style={{ flex: 1, width: "100%" }}
        bootstrapURLKeys={{ key: "AIzaSyC6IjsKHi8RLOzm7oGGS9vw0uYQcs87nYA" }}
        defaultCenter={{ lat: 22.562166, lng: 88.409355 }}
        defaultZoom={14}
        resetBoundsOnResize
        onGoogleApiLoaded={({ map, maps, ref }) => {
          mapref.current = ref;
          setMapInstance(map);
        }}
      >
        {Devices.map((d) => (
          <MapMarker
            lat={parseFloat(d.lat)}
            lng={parseFloat(d.long)}
            key={d.id}
            data={d}
            text={d.location}
            selected={d.dev_id === SelectedDevice?.dev_id}
            onClick={() => {
              setSelectedDevice(d);
            }}
          />
        ))}
      </GoogleMapReact>
      <div className="flex flex-col justify-start items-stretch absolute top-20 right-20 p-4 rounded-md shadow-md z-10 bg-gray-800">
        {!!SelectedDevice ? (
          <>
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 text-3xl">
              <div>Noise Level: </div>
              <div className="text-gray-50 text-4xl px-4">
                {SelectedDevice.laeqt} db(A)
              </div>
              <div
                className="rounded-full w-8 h-8"
                style={{
                  backgroundColor: getNoiseLevelColorCode(
                    parseFloat(SelectedDevice.laeqt),
                    SelectedDevice.zone as NoiseLevelZoneType
                  ),
                }}
              ></div>
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
              <div>Device Location: </div>
              <div className="text-gray-50 text-xl font-bold px-4">
                {SelectedDevice.location}
              </div>
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
              <div>Device Zone: </div>
              <div className="text-gray-50 text-xl font-bold px-4">
                {SelectedDevice.zone}
              </div>
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-sm">
              <div>District </div>
              <div className="text-gray-50 text-l font-bold px-5">
                {SelectedDevice.district}
              </div>
            </div>
            <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-sm">
              <div>Timestamp </div>
              <div className="text-gray-50 text-l font-bold px-5">
                {moment(data?.created_at).format("MMMM Do YYYY, h:mm a")}
              </div>
            </div>
            <div className="flex flex-row justify-center items-center m-2">
              <table className="bg-gray-700 w-full table">
                <thead className="table-header-group">
                  <tr className="table-row">
                    <th className="p-2 text-white bg-gray-700 mx-1 text-left">
                      Parameter
                    </th>
                    <th className="p-2 text-white bg-gray-700 mx-1 text-left">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="table-row-group p-2 text-gray-200">
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LAs</td>
                    <td className="p-2">{AliveData?.las}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LCs</td>
                    <td className="p-2">{AliveData?.lcs}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LZs</td>
                    <td className="p-2">{AliveData?.lzs}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LAeqt</td>
                    <td className="p-2">{data?.laeqt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LCeqt</td>
                    <td className="p-2">{data?.lceqt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LZeqt</td>
                    <td className="p-2">{data?.lceqt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LApeakt</td>
                    <td className="p-2">{data?.lapeakt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LCpeakt</td>
                    <td className="p-2">{data?.lcpeakt}</td>
                  </tr>
                  <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                    <td className="p-2">LZpeakt</td>
                    <td className="p-2">{data?.lzpeakt}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MapView;
