import React from 'react';
import { getDeviceListReturnItemType } from '../api';
import { getNoiseLevelColorCode, NoiseLevelZoneType } from '../util/noise_color_getter';
export interface MapMarker_props {
  lat: number;
  lng: number;
  text: string;
  data: getDeviceListReturnItemType;
  selected: boolean;
  onClick: () => void;
}
export const MapMarker: React.FC<MapMarker_props> = ({
  selected,
  data,
  onClick,
}) => {
  const color = getNoiseLevelColorCode(
    parseFloat(data.laeqt),
    data.zone as NoiseLevelZoneType
  );
  return (
    <div
      className={`h-10 w-10 rounded-full text-white border-2 border-white flex flex-row justify-center items-center ${
        selected ? "animate-bounce" : "animate-none"
      }`}
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      <span
        className="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
        style={{ backgroundColor: color }}
      ></span>
      {data.laeqt}
    </div>
  );
};
