import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { reset } from "../slices/user.slice";
import { useAppDispatch, useAppSelector } from "../util/hooks";

export const Header = () => {
  const { location } = useHistory();
  const [path, setpath] = useState(location.pathname);
  const dispatch = useAppDispatch();
  const { username, state } = useAppSelector((s) => s.user);
  return (
    <div
      className="flex flex-col sm:flex-row justify-between items-center bg-blue-500 text-white text-lg px-4 py-2"
      style={{
        minHeight: 60,
        backgroundImage:
          "linear-gradient(90deg, rgb(255,255,255) 0%, rgb(0,8,81) 100%)",
      }}
    >
      <div className="flex flex-row justify-start gap-4 items-center">
        <img
          src="SClogo.png"
          alt="Swan Court Logo"
          className="max-w-screen-sm h-14 rounded"
        />

        <div className="text-gray-600  drop-shadow-lg filter text-3xl rounded p-2 font-sans font-bold">
          ANMS History Data - Swan Court
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-end items-center gap-4">
        <Link
          to="/map"
          onClick={(e) => setpath("/map")}
          className={`text-white border-2 rounded p-2 font-sans text-sm hover:bg-white hover:text-blue-500 ${
            path === "/map" ? "border-b-8" : "text-white bg-transparent"
          }`}
        >
          Map View
        </Link>
        <Link
          to="/history"
          onClick={(e) => setpath("/history")}
          className={`text-white border-2 rounded p-2 font-sans text-sm hover:bg-white hover:text-blue-500 ${
            path === "/history" ? "border-b-8" : "text-white bg-transparent"
          }`}
        >
          DataGrid
        </Link>
        <Link
          to="/graph"
          onClick={(e) => setpath("/graph")}
          className={`text-white border-2 rounded p-2 font-sans text-sm hover:bg-white hover:text-blue-500 ${
            path === "/graph" ? "border-b-8" : "text-white bg-transparent"
          }`}
        >
          Charts
        </Link>
        {state === "loggedIn" ? (
          <>
            <div className="text-white p-2 font-sans text-sm border-b-4 border-white">
              {username}
            </div>
            <Link
              to="/signin"
              onClick={(e) => {
                setpath("/signin");
                dispatch(reset());
              }}
              className={`text-white border-2 rounded-full p-2 font-sans text-sm hover:bg-white hover:text-blue-500 ${
                path === "/signin" ? "border-b-8" : "text-white bg-transparent"
              }`}
            >
              Logout
            </Link>
          </>
        ) : null}
      </div>
    </div>
  );
};
