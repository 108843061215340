import React, { useMemo, useRef } from "react";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
  ToolbarItems,
  ExcelExport,
  Inject,
  Toolbar,
  PdfExport,
  Filter,
  Sort,
  FilterSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import moment from "moment";
import { IBaseComponent } from "./types";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../store";
enableRipple(true);

export interface IHistoryDatagridComponent extends IBaseComponent {
  rowIndexValue: React.MutableRefObject<number>;
}
export const HistoryDatagrid: React.FC<IHistoryDatagridComponent> = ({
  Devices,
  SelectedDevice,
  setSelectedDevice,
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  Data,
  TotalData,
  rowIndexValue,
}) => {
  const gridRef = useRef<Grid | null>(null);
  const toolbar: ToolbarItems[] = [
    "ExcelExport",
    "PdfExport",
    "CsvExport",
    "Search",
    "Print",
  ];
  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (gridRef.current === null || !args) return;
    console.log("Event Type: ", args.item);
    switch (args.item.text) {
      case "Excel Export":
        rowIndexValue.current = 0;
        gridRef.current.excelExport({
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
            "ll"
          )}.xlsx`,
        });
        break;
      case "PDF Export":
        rowIndexValue.current = 0;
        gridRef.current.pdfExport({
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
            "ll"
          )}.pdf`,
          theme: {
            header: {
              fontSize: 12,
              bold: true,
            },
            record: {
              fontSize: 11,
            },
          },
          pageOrientation: "Landscape",
          // header: {
          //   contents: [
          //     {
          //       type: "Text",
          //       value: `Device Location: ${SelectedDevice?.location}, ${
          //         SelectedDevice?.street
          //       } | Device Id: ${SelectedDevice?.dev_id} | Start Date: ${moment(
          //         StartDate
          //       ).format("ll")} | EndDate: ${moment(EndDate).format("ll")}`,
          //     },
          //   ],
          // },
          // footer: {
          //   contents: [
          //     {
          //       type: "PageNumber",
          //       pageNumberType: "Arabic",
          //     },
          //   ],
          // },
        });
        break;
      case "CSV Export":
        rowIndexValue.current = 0;
        gridRef.current.csvExport({
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
            "ll"
          )}.csv`,
        });
        break;
    }
  };

  const DropDownListItems: ItemModel[] = useMemo(
    () =>
      Devices.map((d) => {
        const item: ItemModel = {
          text: `${d.location}, ${d.street}`,
          id: d.dev_id,
        };
        return item;
      }),
    [Devices]
  );
  const { state } = useSelector((state: RootState) => state.user);
  if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <div className="flex flex-col justify-start items-center w-full">
      <div className="flex flex-col sm:flex-row justify-start items-center w-full gap-8   ">
        <DropDownButtonComponent
          id="element"
          items={DropDownListItems}
          select={(e: any) => {
            console.log(e.item.properties.id);
            setSelectedDevice(
              Devices.find((d) => d.dev_id === e.item.properties.id)
            );
          }}
        >
          {typeof SelectedDevice === "undefined"
            ? "Select Device"
            : `${SelectedDevice.location}, ${SelectedDevice.street}`}
        </DropDownButtonComponent>
        <div className="w-2/12">
          <DatePickerComponent
            id="datepicker"
            value={StartDate}
            placeholder="Enter Start Date"
            onChange={(e: any) => {
              console.log(e);
              setStartDate(new Date(e.value));
            }}
          />
        </div>
        <div className="w-2/12">
          <DatePickerComponent
            id="datepicker"
            placeholder="Enter End date"
            value={EndDate}
            min={StartDate}
            onChange={(e: any) => {
              console.log(e);
              setEndDate(new Date(e.value));
            }}
          />
        </div>
        <div className="flex flex-1 justify-end items-center">
          Showing {TotalData} rows.
        </div>
      </div>
      <GridComponent
        dataSource={Data}
        ref={(g) => (gridRef.current = g)}
        toolbar={toolbar}
        filterSettings={filterOptions}
        toolbarClick={toolbarClick}
        allowExcelExport
        allowPdfExport
        allowFiltering
        allowSorting
        showColumnMenu={false}
        enableAltRow
        dataBound={(e) => {
          rowIndexValue.current = 0;
          console.log("Resetting Row Index Value");
        }}
        beforeDataBound={(e) => {
          rowIndexValue.current = 0;
          console.log("Resetting Row Index Value");
        }}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            headerText="Sl no."
          />
          <ColumnDirective field="location" headerText="Location" />
          <ColumnDirective
            field="timestamp"
            textAlign="Left"
            headerText="Timestamp"
            width={170}
          />
          <ColumnDirective field="las" textAlign="Left" headerText="LA(s)" />
          <ColumnDirective field="lcs" textAlign="Left" headerText="LC(s)" />
          <ColumnDirective field="lzs" textAlign="Left" headerText="LZ(s)" />
          <ColumnDirective field="laeqt" textAlign="Left" headerText="LA(eqt)" />
          <ColumnDirective field="lceqt" textAlign="Left" headerText="LC(eqt)" />
          <ColumnDirective field="lzeqt" textAlign="Left" headerText="LZ(eqt)" />
          <ColumnDirective
            field="lapeakt"
            textAlign="Left"
            headerText="LA(peakt)"
          />
          <ColumnDirective
            field="lcpeakt"
            textAlign="Left"
            headerText="LC(peakt)"
          />
          <ColumnDirective
            field="lzpeakt"
            textAlign="Left"
            headerText="LZ(peakt)"
          />
        </ColumnsDirective>
        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort]} />
      </GridComponent>
    </div>
  );
};
