import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";
import { enableRipple } from "@syncfusion/ej2-base";
import React, { useMemo, useRef } from "react";
import { getHistoricalDataReturnItemType } from "../api";
import {
  Category,
  ChartComponent,
  DataLabel,
  LineSeries,
  Legend,
  Tooltip,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  DateTimeCategory,
  Export,
} from "@syncfusion/ej2-react-charts";
import { IBaseComponent } from "./types";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../store";
import { getNoiseDataParameterName } from "../util/noise_data_parameter_name";

export interface IGraphReportComponent extends IBaseComponent {
  SelectedDataParam: string | undefined;
  setSelectedDataParam: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}
enableRipple(true);
export const GraphReport: React.FC<IGraphReportComponent> = ({
  Devices,
  SelectedDevice,
  setSelectedDevice,
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  Data,
  SelectedDataParam,
  setSelectedDataParam,
  TotalData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const unused_parameters = {
    id: null,
    dev_id: null,
    timestamp: null,
    rssi: null,
    power_good: null,
    battery: null,
    createdAt: null,
    updatedAt: null,
    lat: null,
    long: null,
    tamper: null,
    solar_good: null,
    temperature: null,
    location: null
  };
  const DeviceDataParameters = useMemo(() => {
    if (Data.length === 0) {
      const DeviceParams: ItemModel[] = [];
      return DeviceParams;
    }
    let item: getHistoricalDataReturnItemType = Data[0];
    return Object.keys(item)
      .map(
        (i) =>
          ({
            text: getNoiseDataParameterName(i),
            id: i,
          } as ItemModel)
      )
      .filter((i) => !(!i?.id || i.id in unused_parameters));
  }, [Data]);

  const DeviceDropDownListItems: ItemModel[] = useMemo(
    () =>
      Devices.map((d) => {
        const item: ItemModel = {
          text: `${d.location}, ${d.street}`,
          id: d.dev_id,
        };
        return item;
      }),
    [Devices]
  );
  const DownloadOptions: ItemModel[] = useMemo(
    () =>
      ["JPEG", "PNG", "SVG", "PDF"].map(
        (i) =>
          ({
            text: i,
            id: i,
          } as ItemModel)
      ),
    []
  );
  const chartRef = useRef<ChartComponent | null>(null);
  const screenRef = useRef<HTMLDivElement | null>(null);
  const { state } = useSelector((state: RootState) => state.user);
  if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <div
      className="flex flex-1 flex-col justify-start items-center w-full"
      ref={(r) => (screenRef.current = r)}
    >
      <div className="flex flex-col sm:flex-row justify-start items-center w-full gap-8">
        <DropDownButtonComponent
          id="deviceList"
          items={DeviceDropDownListItems}
          select={(e: any) => {
            console.log(e.item.properties.id);
            setSelectedDevice(
              Devices.find((d) => d.dev_id === e.item.properties.id)
            );
          }}
        >
          {typeof SelectedDevice === "undefined"
            ? "Select Device"
            : `${SelectedDevice.location}, ${SelectedDevice.street}`}
        </DropDownButtonComponent>
        <div className="w-2/12">
          <DatePickerComponent
            id="datepicker"
            value={StartDate}
            placeholder="Enter Start Date"
            onChange={(e: any) => {
              console.log(e);
              setStartDate(new Date(e.value));
            }}
          />
        </div>
        <div className="w-2/12">
          <DatePickerComponent
            id="datepicker"
            placeholder="Enter End date"
            value={EndDate}
            min={StartDate}
            onChange={(e: any) => {
              console.log(e);
              setEndDate(new Date(e.value));
            }}
          />
        </div>
        <DropDownButtonComponent
          id="paramsList"
          items={DeviceDataParameters}
          select={(e: any) => {
            console.log(e.item.properties.id);
            setSelectedDataParam(e.item.properties.id);
          }}
        >
          {typeof SelectedDataParam === "undefined"
            ? "Select Data Parameter"
            : getNoiseDataParameterName(SelectedDataParam)}
        </DropDownButtonComponent>
        <div className="flex flex-1 justify-end items-center">
          Showing {TotalData} points.
        </div>
        <DropDownButtonComponent
          id="downloadOptions"
          items={DownloadOptions}
          select={(e: any) => {
            console.log("Downloading ", e.item.properties.id);
            if (chartRef.current === null)
              enqueueSnackbar(`Chart is not loaded yet.`, {
                variant: "error",
              });
            else {
              chartRef.current.exportModule.export(
                e.item.properties.id,
                `${SelectedDevice?.location},${SelectedDevice?.street}-${moment(
                  StartDate
                ).format("ll")}-${moment(EndDate).format("ll")}.${String(
                  e.item.properties.id
                ).toLowerCase()}`
              );
            }
          }}
        >
          Download
        </DropDownButtonComponent>
      </div>
      <div className="flex-1">
        {Data.length > 0 &&
        !!SelectedDataParam &&
        screenRef.current !== null ? (
          <ChartComponent
            id="graph"
            ref={(c) => (chartRef.current = c)}
            primaryXAxis={{ valueType: "DateTimeCategory" }}
            tooltip={{ enable: true }}
            style={{
              marginTop: 20,
            }}
            width={`${screenRef.current.clientWidth - 40}px`}
          >
            <Inject
              services={[
                LineSeries,
                Legend,
                Tooltip,
                DataLabel,
                Category,
                Export,
                DateTimeCategory,
              ]}
            />
            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={Data}
                xName="timestamp"
                yName={SelectedDataParam}
                name={`${SelectedDataParam?.toUpperCase()} Data: ${
                  SelectedDevice?.location
                }, ${SelectedDevice?.street} From ${moment(StartDate).format(
                  "ll"
                )} To ${moment(EndDate).format("ll")}`}
                animation={{ enable: true, duration: 1200, delay: 100 }}
                type="Line"
                marker={{ visible: true, width: 5, height: 5 }}
                enableTooltip
              />
            </SeriesCollectionDirective>
          </ChartComponent>
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
};
