export const getNoiseDataParameterName = (param: string) => {
    switch (param) {
        case 'las':
            return "LA(s)";
        case 'lcs':
            return "LC(s)";
        case 'lzs':
            return "LZ(s)";
        case 'laeqt':
            return "LA(eqt)";
        case 'lceqt':
            return "LC(eqt)";
        case 'lzeqt':
            return "LZ(eqt)";
        case 'lapeakt':
            return "LA(peakt)";
        case 'lcpeakt':
            return "LC(peakt)";
        case 'lzpeakt':
            return "LZ(peakt)";
        default:
            return param;
    }
}